<template>
  <div class="backend-iframe">
    <iframe :src="iframeUrl" ref="iframe"></iframe>
  </div>
</template>

<script>
export default {
  name: "BackendIframe",
  computed: {
    apiDomain() {
      return process.env.VUE_APP_API_DOMAIN;
    },
    iframeUrl() {
      // Load API URL with the same path
      return this.apiDomain + document.location.pathname;
    }
  },
  mounted() {
    this.$refs.iframe.onload = () => {
      this.iframeAutoHeight();
      window.scrollTo(0, 0);
    };

    window.addEventListener("message", this.messageReceived);
    window.addEventListener("resize", this.iframeAutoHeight);
  },
  beforeDestroy() {
    window.removeEventListener("message", this.messageReceived);
    window.removeEventListener("resize", this.iframeAutoHeight);
  },
  methods: {
    postMessage(data) {
      this.$refs.iframe.contentWindow.postMessage(data, this.apiDomain);
    },

    messageReceived(e) {
      // Check that origin is from backend
      if (e.origin.startsWith(this.apiDomain)) {
        let data = e.data;
        if (data.type === "heightRequest")
          this.$refs.iframe.style.height = data.value + "px";
      }
    },

    // Tell iframe to tell us its height so we can set it
    iframeAutoHeight() {
      this.postMessage({
        type: "heightRequest"
      });
    }
  }
}
</script>